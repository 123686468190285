import moment from 'moment'

export default {
  filters: {
    localeLabel (locale) {
      const labels = { 'en': 'English', 'es': 'Español'}
      return labels[locale]
    }
  },
  methods: {
    isLocaleAvailable (locale) {
      return this.$i18n.availableLocales.indexOf(locale) > - 1
    },
    setLocale (locale) {
      if (this.isLocaleAvailable(locale)) {
        this.$i18n.locale = locale
        moment.locale(locale)
      }
      return this.$i18n.locale
    }
  }
}
