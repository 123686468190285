<template>

  <div ref="cover-container" class="cover-container container-cover">
    <div v-if="article" class="cover-illustration" :id="`container_${uniqVideoId}`" :ref="uniqVideoId">
      <VideoMcpViewer :regular-player="true" :mcp-id="`${publicPath}${article.story.cover_video}`" :video-uniq-id="uniqVideoId" :play-video="playVideo" class="container-video-multimedia" :bg-loop="true" />
      <!-- <div class="cover-illustration ">
        <img :src="article.story.cover" class="cover-img" />
      </div> -->
    </div>
    <div class="container">
      <div class="cover-intro">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="intro">
              <h3 class=""><img class="m-0 logo-univ" width="100%" src="@/assets/logo_univision.png" /></h3>
              <h2 class="title-page">
                <!-- <a target="_blank" :href="article.story.liveblog_url"> -->
                  <introText />
                <!-- </a> -->
              </h2>
              <h4> {{ src.story.title }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss">
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translatey(5vh);
    }

    to {
      opacity: 1;
      transform: translateY(0vh);
    }
  }
  
  .btn-vote-home {
    font-size: 21px;
  }

  .cover-social {
    margin: 25px auto;
    text-align: right;

    a {
      color: #fff;
      margin-right: 15px;
    }
  }

  .container-btn {
    margin: 25px 0;

    .btn-transmision {
      background-color: #a48c60;
      font-family: "AkiraExpandedSuperBold";
      font-size: 12px;
      font-weight: 400;
      border-radius: 25px;
    }
  }

  .cover-intro {
    height: 100vh;
    min-height: 720px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .date-published {
    margin: 25px 0;
    font-size: 21px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    color: #ffd26ec7;
  }

  .container-cover {
    position: relative;
    width: 100%;

    .logo-univ {
      max-width: 120px;
      margin: 15px 0;
    }

    .btn-cover {
      border: solid;
      color: white;
      background: rgb(49, 48, 48);
    }

    a {
      text-decoration: none;
      color: white;
    }

    .container {
      max-width: 1320px;
      height: 100vh;
      min-height: 720px;
    }

    .intro {
      float: left;

      .btn-transmision {
        background-color: #a48c60;
        font-family: "AkiraExpandedSuperBold";
        font-size: 12px;
        font-weight: 400;
        border-radius: 25px;

      @media (max-width: 375px) {
        font-size: 10px;
      }

      @media (max-width: 560px) {
        font-size: 10px;
      }

      }

    }
  }

  .cover-illustration {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    min-height: 720px;
    background-size: cover;
    background-color: #000;

    .container-video-mcp {
      width: 100%;
      height: 100vh;
      min-height: 720px;
      overflow: hidden;
      z-index: 1;
    }

    .cover-img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100vh;
      min-height: 720px;
      opacity: 0.5;
      pointer-events: none;
    }

    .cover-video video {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100vh;
      min-height: 720px;
    }

    .video-player {
      position: sticky;
      top: 0;
      width: 100%;
      height: 100vh;
    }
  }

  .cover-container {
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    opacity: 1;
    animation-name: slideUp;
    animation-duration: .75s; /* or: Xms */
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */

    @media (max-width: 560px) {
      min-height: 100vh;
    }

    h2 {
      color: #fff;
      font-family: var(--app-header-font);
      font-weight: 700;
      text-align: left;
      line-height: 1.12;
      text-shadow: 1px 1px 10px #000;
      font-size: 97px;
      text-transform: uppercase;
      letter-spacing: 1.5px;

      a {
        &:hover {
          color: #fff;
          text-shadow: 1px 1px 10px #a48c60;
        }
      }

      @media (max-width: 375px) {
        font-size: 71px;
      }

      @media (max-width: 560px) {
        font-size: 75px;
      }

      span {
        display: block;
      }

      img {
        width: 100%;
        pointer-events: none;
      }
    }

    p {
      color: #000;
    }

    

    h4 {
      color: #fff;
      font-family: "AkiraExpandedSuperBold"
    }

    .image-wrapper {
      width: 100%;
      pointer-events: none;

      @media (min-width: 900px) {
        position: absolute;
        top: 0;
        height: 250vh;
      }
    }

  }

</style>


<script>

  import introText from '@/components/introText.vue'
  import VideoMcpViewer from '@/components/VideoMcpViewer.vue'
  import timeoutMixins from '@/mixins/timeout.js'

  export default {
    name: "CoverStory",
    components: {
      VideoMcpViewer,
      introText
    },
    mixins: [timeoutMixins],
    props: {
      src: Object
    },
    data () {
      return {
        playVideo: true,
        uniqVideoId: `component_video_${this._uid}`,
        publicPath: process.env.BASE_URL,
        timeOut: false
      }
    },
    computed: {
      article() {
        return require(`@/i18n/es.json`)
      }
    },
    mounted() {
      this.validationTime()

      setTimeout(() => {
        this.$refs['cover-container'].style.opacity=1
      }, 200)

      window.setInterval(() => {
        this.validationTime()
      }, 60000)

    },
    methods: {
      countLink(link) {
        window.dataLayer.push({
          event: "general_click",
          event_action: link
        })

        console.log( window.dataLayer.push({
          event: "general_click",
          event_action: link
        }))
      }
    }
  }
</script>