<template>
  <div class="container-univisionarios">
      <div class="container container-fade">  
              
        <div class="row justify-content-center">
          <div class="col-12 col-md-9 col-lg-6">
            <div class="pt-5 mb-5">
              <img alt="Univisionarios" width="100%" :src="`${publicPath}assets/images/icons-categories-mobile3.png`" />              
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-9 col-lg-6">            
            <h3><img alt="Univisionarios" width="100%" :src="`${publicPath}assets/images/logo_univ.png`" /></h3>
            <h2 class="sub-title">{{ article.story.univisionarios.subtitle }}</h2>
          </div>
        </div>
        
        <div class="row justify-content-center">
          <div class="col-md-9  col-lg-6 col-12">
            <div class="row articulo">
              <div class="col-12">
                <div v-html="article.story.univisionarios.content">

                </div>

              </div>
                
            </div>
            
            <div class="row linea">
              <div class="col-12">
                <div class="text-center mb-5 mt-5 ">              
                  <router-link :to="{name: 'acerca'}" custom v-slot="{ navigate, href }">
                    <a class="btn-round" :href="href" @click="countLink(`saber_mas`, 'acerca')">Acerca de</a>
                  </router-link>
                </div>
              </div>
            </div>

          </div>


        </div>        
      </div>      
    </div>
</template>

<script>


export default {
  name: 'Univisionarios',
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    countLink(link) {
      console.log(link)
      window.dataLayer.push({
        event: "general_click",
        event_action: link
      })
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
  computed: {
    article() {
      return require(`@/i18n/es.json`)
    }
  }
}
</script>
