<template>
<div class="container-detalle">
  <div class="nominados-lista-homenajeados">
    <div class="row mb-3 justify-content-center">      
      <div class="col-12 mb-4 mt-5">
        <div class="nominado-item" v-for="(n, ix) in nominados_homenajeados" :key="ix">
          <div class="">
            <router-link :to="{name: n.name_page, params: {'id': n.id}}">
              <img width="100%" :src="`${publicPath}assets/images/nominados/${n.id}.png`" />
              <div class="nominado-name">
                {{ n.name }}
              </div>
            </router-link>
          </div>
        </div>
      </div>

    </div>

  </div>
  
</div>

</template>

<style lang="scss">
  .nominados-lista-homenajeados {
    text-align: center;

    a {
      color: #000;
      text-decoration: none;
    }

    .nominado-name {
      display: inline-block;
      max-width: 120px;
      font-size: 14px;
      text-transform: uppercase;
      font-family: "AkiraExpandedSuperBold";
      border-bottom: solid 1px #bd9767ff;
      text-align: center;

      &:hover {
        color: #bd9767ff;
      }
    }

    .nominado-item {
      width: 100%;
      max-width: 200px;
      margin: 15px 5px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 600px) {
        width: 43%;
      }
    }
  }
</style>

<script>

export default {
  name: 'NewHomenajeados',
  props: {
    nominados_homenajeados: {
      type: Array,
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
