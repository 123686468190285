<template>
  <div v-if="article">
    <div><Navbar :values="article" /></div>

    <CoverStory id="list" data-scroll="25" data-analytics="home" data-section="list" :src="article" class="sections-univ" />

    <Univisionarios data-section="univisionarios" id="univisionarios" class="sections-univ"/>


    <Homenajeados data-analytics="homenajeados"  data-section="homenajeados" id="homenajeados" class="sections-univ"/>

    


    <!-- <Categorias data-section="categorias"  id="categorias" class="sections-univ"/>     -->

    <!-- <MiUnivisionarios/> -->
  
    <!-- Maestros -->
    <!-- <Maestros :nominados_list="nominados" data-section="maestros" id="maestros" class="sections-univ"/> -->

    <!-- Pioneros -->
    <!-- <Pioneros :nominados_list="nominados" data-section="pioneros"  id="pioneros" class="sections-univ" /> -->

    <!-- Emprendedores -->
    <!-- <Emprendedores 
    :nominados_list="nominados" 
    data-section="emprendedor"  id="emprendedor" class="sections-univ"  /> -->

    <!-- <Innovadores :nominados_list="nominados" data-scroll="75" data-analytics="innovadores"  data-section="innovadores"  id="innovadores" class="sections-univ"  /> -->

    <!-- <Iconos data-section="iconos" id="iconos" class="sections-univ" :nominados_list="nominados" /> -->

    <!-- <MainJurado data-scroll="100" data-analytics="comite" data-section="jurado" id="jurado" class="sections-univ"/> -->

    <Footer />

    <SocialShare :values="article" />
  
  </div>
  
</template>

<style lang="scss">
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translatey(5vh);
    }

    to {
      opacity: 1;
      transform: translateY(0vh);
    }
  }

  .sections-univ {
    &.active {
      .container-fade {
        top: 0;
        opacity: 1;
        animation-name: slideUp;
        animation-duration: .75s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
      }      
    }
    
    .container-fade {
      opacity: 0;
    } 
  }

</style>

<script>

  // Localization settings
  import i18n from '@/mixins/i18n'
  import Navbar from '@/components/Navbar'
  import Univisionarios from '@/pages/Univisionarios'
  // import Categorias from '@/pages/Categorias'    
  // import MainJurado from '@/pages/MainJurado'
  import CoverStory from '@/components/CoverStory'

  // import Innovadores from '@/pages/Innovadores'
  // import Emprendedores from '@/pages/Emprendedores'
  // import Maestros from '@/pages/Maestros'
  // import Pioneros from '@/pages/Pioneros'  
  // import Iconos from '@/pages/Iconos'
  import Homenajeados from '@/pages/Homenajeados' 
  // import MiUnivisionarios from '@/pages/MiUnivisionarios'

  import Footer from '@/pages/Footer'

  import SocialShare from '@/components/SocialShare'

  export default {
    name: 'Home',
    mixins: [i18n],
    components: {
      Footer,
      Navbar,
      CoverStory,
      Univisionarios,
      // Categorias,
      // MiUnivisionarios,
      // MainJurado,
      // Innovadores,
      // Emprendedores,
      // Maestros,
      // Pioneros,
      // Iconos,
      SocialShare,
      Homenajeados
    },
    props: {
      locale: {
        type: String,
        default: null
      }
    },
    beforeMount () {
      this.setLocale(this.$route.params.locale)
      window.scrollTo(0, 0)
    },
    mounted() {
      if(this.$route.name != 'home' || this.$route.name !='list') {
        if(document.getElementById(this.$route.name)) {
          const top = document.getElementById(this.$route.name).offsetTop
          document.getElementById(this.$route.name).classList.add('active')
          window.scrollTo(0, top)
        }
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.setLocale(from.params.locale)
      if(to.name != 'list') {
        window.scrollTo(0, 0)        
      }        
      next()
    },
    computed: {
      article() {
        return require(`@/i18n/es.json`)
      },
      nominados() {
        return require(`@/i18n/nominados.json`)
      }
      
    }
  }
</script>