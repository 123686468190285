<template>
  <div class="container-homenajeados">
    <div><Navbar :values="article" /></div>
    <div class="container">
      <div class="row mt-5">        
        <div class="col-12 col-md-5">          
          <div class="text-center mt-3 mb-3">
            <img style="color: #000;" width="80%" :src="`${publicPath}assets/images/logo-black-univision.jpeg`" />
          </div>          
          <div class="line-top mt-3"></div>          
          <div class="text-center mt-3">
            <h2>HOMENAJEADOS</h2>
          </div>
        
        </div>
        <div class="col-12 col-md-7">
          <div class="mt-3 mb-3">
            <div class="homenajeados-parrafo text-left text-md-right">
              <p>Univision Noticias honra la excelencia hispana y ya tiene a sus Univisionarios, esos latinos dentro y fuera de Estados Unidos que con su trabajo, iniciativas y emprendimientos contribuyen a mejorar la sociedad, la cultura y las comunidades que los rodean.</p>
            </div>            
          </div>
        </div>
      </div>

      <div class="row justify-content-center">        
        <div class="col-12 col-md-12">
          <NewHomenajeados :nominados_homenajeados="allhomenajeados" />
        </div>          
      </div>

    </div>
    
  </div>
</template>

<script>  
  import sortBy from 'lodash/sortBy'
  import NewHomenajeados from '@/components/NewHomenajeados'
  import timeoutMixins from '@/mixins/timeout.js'
  
  import Navbar from '@/components/Navbar'

  export default {
    name: 'Homenajeados',
    mixins: [timeoutMixins],
    components: {
      NewHomenajeados,      
      Navbar
    },
    data () {
      return {
        timeOut: false,
        publicPath: process.env.BASE_URL
      }
    },
    computed: {
      article() {
        return require(`@/i18n/es.json`)
      },
      allhomenajeados() {
        const homenaje = this.nominados_homenajeados

        return sortBy(homenaje, ['name', 'asc'])
      },
      nominados_homenajeados() {
        return require('@/i18n/homenajeados.json')
      }
    },
    mounted() {
      this.validationTime()
    },
    methods: {
      countLink(link) {
        window.dataLayer.push({
          event: "general_click",
          event_action: link
        })
      }
    }
  }
</script>


